.story-title {
  color: #140f0f;
  font-family: CerebriSansMedium;
  font-size: 24px;
  margin-bottom: 6px;
}

.story-title.read {
  opacity: 0.3;
}

.story-title.dark {
  color: #E6E6E6;
}

.story-title.dark.read {
  opacity: 0.4;
}

@media screen and (max-width: 600px) {
  .story-title {
    font-size: 18px;
    line-height: 24px;
  }
}

.story-byline {
  color: #7F7F7F;
  font-family: 'IBM Plex';
  font-size: 16px;
  margin-bottom: 6px;
}

.story-byline.read {
  opacity: 0.3;
}

.story-byline.dark {
  color: #808080;
}

.story-byline.dark.read {
  opacity: 0.4;
}

@media screen and (max-width: 600px) {
  .story-byline {
    font-size: 14px;
    line-height: 20px;
  }
}

.story-summary {
  color: #191919;
  font-family: 'IBM Plex';
  font-size: 16px;
  margin-bottom: 48px;
  line-height: 28px;
  /* max-lines: 3; */
}

.story-summary.read {
  opacity: 0.3;
}

.story-summary.dark {
  color: #E6E6E6;
}

.story-summary.dark.read {
  opacity: 0.4;
}

@media screen and (max-width: 600px) {
  .story-summary {
    font-size: 14px;
    line-height: 20px;
  }
}

.truncate-overflow {
  --max-lines: 3;
  overflow: hidden;
  /* space for ellipsis */
  -webkit-line-clamp: 3;
  /* number of lines to show */
  line-height: 28px;
  /* fallback */
  max-height: 84px;
  /* fallback */
}
